.button{
    height: 2.5rem;
    border: none;
    border-radius: var(--border-radius);
    box-shadow: var(--button-shadow);
    color: var(--color-cream);
    font-family: var(--button-font);
    transition: transform 0.3s ease;
}

.button.request-button-bgc{
    background-color: var(--color-emerald-green);
}

.button.request-button-width{
    width: 17.5rem;
}

.button.request-button-fs{
    font-size: var(--button1-size);
}

.button.request-button-hover:hover{
    background-color: var(--color-beige);
    color: var(--color-dark-green);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.5);
    transform: translateY(0.25rem) translateX(-0.25rem);
    cursor: pointer;
}

.button.more-info-button-bgc{
    background-color: var(--color-dark-green);
}

.button.more-info-button-width{
    width: 11.125rem;
}

.button.more-info-button-fs{
    font-size: var(--button2-size);
}

.button.more-info-button-hover:hover{
    background-color: var(--color-beige);
    color: var(--color-dark-green);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.5);
    transform: translateY(0.25rem) translateX(-0.25rem);
    cursor: pointer;
}

.button.send-button-bgc{
    background-color: var(--color-emerald-green);
}

.button.send-button-width{
    width: 7.6875rem;
}

.button.send-button-fs{
    font-size: var(--button1-size);
}

.button.send-button-hover:hover{
    background-color: var(--color-beige);
    color: var(--color-dark-green);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.5);
    transform: translateY(0.25rem) translateX(-0.25rem);
    cursor: pointer;
}

@media screen and (max-width: 430px) {
    .button{
        height: 2.25rem;
    }
    
    .button.request-button-width{
        width: 13.5rem;
    }
    
    .button.request-button-fs{
        font-size: var(--button2-size);
    }

    .button.more-info-button-width{
        width: 10rem;
    }
}

@media screen and (max-width: 390px) {
    .button.send-button-width{
        width: 6rem;
    }

    .button.send-button-fs{
        font-size: var(--button2-size);
    }
    
}

@media screen and (max-width: 320px) {
    .button{
        height: 2rem;
    }
    
    .button.request-button-width{
        width: 10rem;
    }
    
    .button.request-button-fs{
        font-size: 12px;
    }

    .button.more-info-button-width{
        width: 8rem;
    }

    .button.more-info-button-fs{
        font-size: 12px;
    }

    .button.send-button-width{
        width: 4rem;
    }

    .button.send-button-fs{
        font-size: 12px;
    }
}