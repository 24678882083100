.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1;
  }

.menu-hamburguesa {
    position: relative;
  }

.menu-icon {
    cursor: pointer;
    }

.close-icon{
    position: absolute;
    z-index: 3;
    top: 0px;
    right: -30px;
    cursor: pointer;
    font-size: 24px;
    }

.menu-list {
    position: absolute;
    z-index: 2;
    left: -400px;
    top: -35px;
    background-color: var(--color-cream);
    width: 500px;
    height: 500px;
    margin: 0;
    padding: 5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: var(--border-radius);
    }


.list-item {
    list-style: none;
    margin: 0;
    padding: 0;
    }

.menu-link{
    padding: 1rem 1.5rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-dark-green);
    box-shadow: var(--nav2-shadow);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-dark-green);
    font-size: var(--text2-size);
}

a.menu-link {
    text-decoration: none;
}

@media screen and (max-width: 637px) {
    .menu-list{
        width: 350px;
        height: 400px;
        top: -30px;
        left: -290px;
        padding: 4rem;
    }

    .close-icon{
        right: -10px;
    }

    .menu-link{
        padding: 0.5rem;
        font-size: var(--button2-size);
    }
}

@media screen and (max-width: 390px) {
    .menu-icon-img{
        width: 1.5rem;
    }

    .close-icon{
        top: -10px;
    }

    .close-icon-img{
        width: 1.5rem;
    }

    .menu-list{
        width: 300px;
        height: 300px;
        left: -250px;
        top: -25px;
        padding: 3rem 4rem;
    }

    .menu-link{
        padding: 0.375rem;
    }
}

@media screen and (max-width: 320px) {
    .menu-icon-img{
        width: 1rem;
    }

    .close-icon{
        top: -13px;
    }
    
    .close-icon-img{
        width: 1rem;
    }

    .menu-list{
        width: 230px;
        height: 250px;
        top: -15px;
        left: -195px;
        padding: 2rem;
    }

    .menu-link{
        font-size: 14px;
        padding: 0.25rem;
    }
}