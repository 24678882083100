.therapy-bg {
    /* height: 100vh; */
    background: var(--color-white);
  }
  
.hero-therapy{
    padding: 3rem 5rem;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
}

.adult-therapy-title-cont{
    width: 100%;
}

.adult-therapy-title{
    color: var(--color-dark-green);
    text-align: center;
    text-shadow: var(--text-shadow);
    font-family: var(--headline-font);
    font-size: var(--headline1-size);
    letter-spacing: 0.1125rem;
}

.content{
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
}


.therapy-description-box{
    width: 50%;
    padding: 2rem;
    border-radius: var(--border-radius);
    background: var(--color-cream);
    box-shadow: var(--box-shadow);
}

.list-style-none{
    list-style: none;
}

.therapy-info{
    padding: 0.5rem;
    color: var(--Verde-oscuro, #3D8887);
    text-align: justify;
    font-family: Montaga;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0625rem;
}

.therapy-opportunity-box{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
}

.opportunity-title{
    color: #3D8887;
    font-family: Merriweather;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.075rem;
}

.opportunity-box{
    width: 29rem;
    /* height: 7.875rem; */
    border-radius: 0.3125rem;
    background: var(--Beige, #E9DAC1);
    box-shadow: 6px 6px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem;
}

.opportunity-text{
    color: #3D8887;
    font-family: Montaga;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0625rem;
}

@media screen and (min-width: 1025px) and (max-width: 1136px) {
    .hero-therapy {
        height: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .hero-therapy {
        height: 100%;
    }

    .content{
        flex-direction: column;
        gap: 7rem;
    }

    .therapy-description-box, .therapy-opportunity-box, .opportunity-box{
    width: 100%;
    }

}

@media screen and (max-width: 834px) {
    .hero-therapy{
        justify-content: space-evenly;
    }

    .content{
        gap: 2rem;
    }
}

@media screen and (max-width: 744px) {
    .hero-therapy {
        height: 100%;
    }
 }

 @media screen and (max-width: 430px) {
    .hero-therapy{
        padding: 2rem;
    }

    .adult-therapy-title{
        font-size: var(--headline2-size);
    }

    .therapy-info{
        font-size: var(--button1-size);
        padding: 0;
    }

    .therapy-opportunity-box{
        gap: 1rem;
    }

    .opportunity-title{
        font-size: 1rem;
    }

    .opportunity-text{
        font-size: 1rem;
    }
 }

@media screen and (max-width: 390px) {
    .hero-therapy{
        gap: 2rem;
    }

    .adult-therapy-title{
        font-size: var(--text2-size);
    }

    .underline-sup, .underline-inf {
        margin: 0.5rem 0;
    }

    .content{
        gap: 1.5rem;
    }

    .therapy-description-box{
        padding: 1rem;
    }

    .therapy-info{
        font-size: var(--button2-size);
        letter-spacing: 0rem;
    }

    .opportunity-title{
        letter-spacing: 0rem;
    }

    .opportunity-text{
        letter-spacing: 0rem;
    }
 }

 @media screen and (max-width: 320px) {
    .hero-therapy{
        padding: 1.5rem;
        gap: 1.5rem;
    }

    .adult-therapy-title{
        font-size: var(--button2-size);
    letter-spacing: 0;
    }

    .content{
        gap: 1.5rem;
    }
    
    .therapy-info, .opportunity-text{
        font-size: 14px;
    }
 }