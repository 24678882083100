a.nav-link {
    text-decoration: none;
    color: var(--color-dark-green);
}

.links{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
}

.nav-link{
    padding: 0.5rem 0.75rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-dark-green);
    box-shadow: var(--nav2-shadow);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
}

.link:hover{
    background-color: var(--color-dark-green);
    border: 1px solid var(--color-dark-green);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    transform: translateY(0.25rem) translateX(-0.25rem);
    color: var(--color-cream);

}

.home-page .nav-link {
    border: 1px solid var(--color-cream);
    box-shadow: var(--nav1-shadow);
}

.home-page .link:hover {
    background-color: var(--color-dark-green);
    border: 1px solid var(--color-dark-green);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    transform: translateY(0.25rem) translateX(-0.25rem);
}

.home-page a.nav-link{
    color: var(--color-cream);
}