@import url('https://fonts.googleapis.com/css2?family=Montaga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

:root {
  --headline-font: 'Merriweather', serif;
  --text-font: 'Montaga', serif;
  --link-font: 'Merriweather', serif;
  --button-font: 'Merriweather', serif;
  --headline1-size: 2.25rem;
  --headline2-size: 1.5rem;
  --text1-size: 1.5rem;
  --text2-size: 1.25rem;
  --link-size: 1.125rem;
  --button1-size: 1.125rem;
  --button2-size: 1rem;
  --color-dark-grey: #0C1C1C;
  --color-dark-green: #3D8887;
  --color-emerald-green: #1CBB96;
  --color-beige: #E9DAC1;
  --color-cream: #F7ECDE;
  --color-white: #FFFFFF;
  --border-radius: 0.3125rem;
  --nav1-shadow: 2px 2px 4px 0px #FFF5D2;
  --nav2-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  --button-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  --box-shadow: 6px 6px 4px 0px rgba(0, 0, 0, 0.25);
  --text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  --input-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

*{
  box-sizing: border-box; 
  margin: 0;
}