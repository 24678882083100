.contact-bg{
    height: 100vh;
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('../../assets/fondo2.jpg') 50% / cover no-repeat;
}

.hero-contact{
    padding: 3rem 5rem;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;
}

.contact-title-cont{
    width: 100%;
}

.contact-title{
    color: var(--color-dark-green);
    text-align: center;
    text-shadow: var(--text-shadow);
    font-family: var(--headline-font);
    font-size: var(--headline1-size);
    letter-spacing: 0.1125rem;
}

.contact-boxes-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
}

.contact-info{
    padding: 1.5rem;
    width: 50%;
    /* height: 24.25rem; */
    border-radius: var(--border-radius);
    background: var(--color-cream);
    box-shadow: var(--box-shadow);
}

.text-info{
    padding: 1rem;
    color: var(--color-dark-green);
    font-family: var(--text-font);
    font-size: var(--text2-size);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.075rem;
}

spam{
    color: var(--color-emerald-green);
    font-family: var(--text-font);
    font-size: var(--text2-size);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.075rem;
}

@media screen and (max-width: 1024px) {
    .contact-bg{
        height: 100%;
    }

    .hero-contact{
        height: 100%;

    }

    .contact-boxes-container{
        flex-direction: column;
    }

    .contact-info{
        width: 100%;
    }
}

@media screen and (max-width: 744px) {
    .hero-contact {
        gap: 1.5rem;
    }
}

@media screen and (max-width: 527px) {
    .contact-bg{
        height: 100%;
    }

    .hero-contact{
        padding: 2rem;
    }

    .contact-title{
        font-size: var(--headline2-size);
    }

    .contact-boxes-container{
        gap: 1.5rem;
    }

    .text-info{
        padding: 0.5rem;
        font-size: var(--button2-size);
        letter-spacing: 0rem;
    }

    spam{
        font-size: var(--button2-size);
        letter-spacing: 0rem;
    }
}

@media screen and (max-width: 390px) {
    .hero-contact{
        height: 90vh;
    }

    .contact-title{
        font-size: var(--button1-size);
    }

    .contact-boxes-container{
        gap: 1rem;
    }

    .contact-info{
        padding: 1rem;
    }

    .text-info{
        padding: 0.25rem 0;
    }
}

@media screen and (max-width: 320px) {
    .hero-contact{
        gap: 1rem;
        padding: 1rem;
        height: 90vh;
    }

    .contact-title{
        font-size: var(--button2-size);
        letter-spacing: 0;
    }

    .text-info{
        font-size: 14px;
        padding: 0;
    }

    spam{
        font-size: 14px;
    }
}